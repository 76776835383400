export default [
	{
		path: '/operation',
		name: 'operationManage',
		component: () => import( '@/views/index.vue' ),
		meta: { icon: 'pinglun1' },
		children: [
			{
				path: 'banner',
				name: 'operationBanner',
				showSide: true,
				meta: { permission: ['btsViewActivities'] },
				component: () => import( './banner.vue' )
			},
			{
				path: 'configManagement',
				name: 'operationConfigManagement',
				showSide: true,
				// 权限点
				meta: { permission: ['btsViewActivities'] },
				component: () => import( './configManagement/index.vue' )
			}
		]
	}
];
