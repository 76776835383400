const pub = [
	{
		path: ':oemDomain',
		name: 'oemDomainPage',
		component: () => import( '@/views/pub/home/index.vue' ),
		children: [
			{
				path: '',
				name: 'oemDomainPageLogin',
				meta: { needAuth: false },
				component: () => import( '@/views/pub/home/login.vue' )
			},
			{
				path: 'forget',
				name: 'oemDomainPageForget',
				meta: { needAuth: false },
				component: () => import( '@/views/pub/home/forget.vue' )
			},
			{
				path: 'signUp',
				name: 'oemDomainPageSignUp',
				meta: { needAuth: false },
				component: () => import( '@/views/pub/home/signUp.vue' )
			},
			{
				path: 'perfectInfo',
				name: 'oemDomainPagePerfectInfo',
				meta: { needAuth: false },
				component: () => import( '@/views/pub/home/perfectInfo.vue' )
			},
			{
				path: 'signUpSuccess',
				name: 'oemDomainPageSignUpSuccess',
				meta: { needAuth: false },
				component: () => import( '@/views/pub/home/signUpSuccess.vue' )
			},
			{
				path: 'bindAccount',
				name: 'oemDomainPageBindAccount',
				meta: { needAuth: false },
				component: () => import( '@/views/pub/home/bindAccount.vue' )
			}

		]
	}
];

export default pub;
