export default [
	{
		path: '/space/capacity',
		name: 'spaceCapacity',
		meta: {
			icon: 'chucungaikuang',
			permission: ['btsViewSpace']
		},
		component: () => import( './capacity.vue' )
	}
];
