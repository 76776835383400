export default [
	{
		path: '/system',
		name: 'systemManage',
		component: () => import( '@/views/index.vue' ),
		meta: { icon: 'pianhaoshezhi' },
		children: [
			{
				path: 'role',
				name: 'systemRole',
				showSide: true,
				meta: { permission: ['btsViewRole'] },
				component: () => import( './role.vue' )
			},
			{
				path: 'auth',
				name: 'systemAuth',
				showSide: true,
				meta: { permission: ['btsViewAbilityMenu'] },
				component: () => import( './auth.vue' )
			},
			{
				path: 'user',
				name: 'systemUser',
				showSide: true,
				meta: { permission: ['btsViewUser'] },
				component: () => import( './user.vue' )
			},
			{
				path: 'category',
				name: 'systemCategory',
				showSide: true,
				meta: { permission: ['btsViewModelCategory'] },
				component: () => import( './category.vue' )
			},
			{
				path: 'tag',
				name: 'systemTag',
				showSide: true,
				meta: { permission: ['btsViewModelSign'] },
				component: () => import( './tag.vue' )
			},
			{
				path: 'material',
				name: 'systemMaterial',
				showSide: true,
				meta: { permission: ['btsViewModelMaterial'] },
				component: () => import( './material.vue' )
			},
			// HDR环境管理
			{
				path: 'hdr',
				name: 'systemHdr',
				showSide: true,
				meta: { permission: ['btsViewModelHDR'] },
				component: () => import( './hdr.vue' )
			}
		]
	}
];
