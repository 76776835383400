import http from '@/utils/http';
const newRootUrl = '/api';

const Info = {
	/**
	 * 获取个人信息
	 * @returns
	 */
	getInfo() {
		return http.get( `${newRootUrl}/getUserProfile`, { errCode: 'info.getInfo' });
	},
	/**
	 * 当前账户权限点
	 * @returns
	 */
	getPermList( params ) {
		return http.get( `${newRootUrl}/fetchUserPerm`, { params, errCode: 'info.getPermList' });
	}
};
export default Info;
