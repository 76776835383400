import commonApi from '@/api/common/index.js';
import { XlMessageBox } from '@shining3d/ui-components';
import info from '@/api/info/index.js';
import store from '../../store/index.js';
import { VueCookieNext } from 'vue-cookie-next';
import _get from 'lodash/get';
import { useLang } from '@/hooks/lang.js';
import router from '@/router';
import {
	$convertUTCDateToLocalDate, // 地区日期差格式化转换 需要vuex存在时区列表countryList
	$doLang, // 语言转换
	$rdoLang, // lang转换
	$lang, // 获取当前语言
	$formErr, // msg报错处理
	$setLocalStorage, // 本地存储
	$getLocalStorage, // 获取本地存储
	$removeLocalStorage, // 删除本地存储
	$checkPerm, // 需要vuex存在 permsList权限点列表
	$getCdnUrl, // 地址拼接 需要vuex存在 global.cdnUr、global.cloud
	$hasOnePerm, // 单个权限点是否存在，接收两个参数 list perm
	$copyText, // 复制内容到剪切板
	$downloadFile, // 下载文件
	$openUrl, // 新窗口打开
	$downloadByAlink // A 标签下载
} from '@shining3d/common-module/utils/index.js';
const cc = VueCookieNext.getCookie( 'cc' ) || 'CN';

const { $t } = useLang( '@shining3d/app' );

// 获取国家码初始值
function $getPhoneArea( sd ) {
	const PhoneArea = store.state.countryList.find(( val ) => val.id === ( sd || cc ));
	return PhoneArea ? PhoneArea.phone : '86';
}

// 获取个人信息
async function $getInfo() {
	const res = await info.getInfo();
	if ( res.status === 'success' ) {
		// 更新创建时间转换
		res.result.lastOn = $convertUTCDateToLocalDate( res.result.lastOn, store.state.countryList );
		res.result.createOn = $convertUTCDateToLocalDate( res.result.createOn, store.state.countryList );
		// 上次登录地址拼接
		const country = _get( res.result, 'lastRegion.country', '' );
		const city = _get( res.result, 'lastRegion.city', '' );
		const area = _get( res.result, 'lastRegion.area', '' );
		res.result.region = ( country === 'xx' ? ' ' : country ) + ' ' + ( area === 'xx' ? ' ' : area ) + ' ' + ( city === 'xx' ? ' ' : city );
		// 更新个人信息存储
		store.commit( 'setUserInfo', { ...res.result } || {});
		return { ...res.result };
	} else {
		return {};
	}
}

function $exitBySearch( redirectTo ) {
	!redirectTo && console.log( '$exitBySearch ： 退出登录' );
	VueCookieNext.removeCookie( 'token' );
	VueCookieNext.removeCookie( 'user_id' );
	VueCookieNext.removeCookie( 'uTitle' );
	$removeLocalStorage( 'perms' ); // 权限
	$removeLocalStorage( 'userInfo' ); // 用户信息
	$removeLocalStorage( 'defaultDentalOrderReceive' ); // 案例参数
	$removeLocalStorage( 'cancelPromptFlag' );
	// 云平台处理，token存在跳过，入参路径>oem+当前页面路径
	if ( !VueCookieNext.getCookie( 'token' )) {
		window.location.href = redirectTo || router.resolve({ name: 'oemDomainPage', params: { oemDomain: VueCookieNext.getCookie( 'oemURL' ) || 'index' } }).href + window.location.search;
	} else {
		console.log( 'token未清除' );
	}
}

// 获取当前账号权限列表
const $getPermList = async ( type = {}) => {
	const res = await info.getPermList( type );
	console.log( res );
	if ( res.status === 'success' && res.result && res.result.translationCodes ) {
		// 更新账号权限列表
		store.commit( 'setPermsList', res.result?.translationCodes || []);
		// 更新用户信息中的权限列表
		// setOrgList( '', res.result );
		return res.result?.translationCodes || [];
	} else {
		return [];
	}
};
async function $getSettingList({ type, lang }) {
	const res = await commonApi.getSettingList( type );
	if ( res.status === 'success' && res.result && res.result.length > 0 ) {
		return res.result.map(( v ) => {
			const obj = {};
			obj.value = v.keyName;
			try {
				obj.label = JSON.parse( v.other )[ lang ];
			} catch ( error ) {
				obj.label = '';
			}
			return obj;
		});
	} else {
		return [];
	}
}

// 获取国家，国家码列表
async function $getCountry() {
	const res = await commonApi.getCountry();
	if ( res.status === 'success' ) {
		const countryList = res.result.map(( val ) => ({
			...val,
			value: val.id,
			label: val.name === val.native ? val.name : val.name + ' ' + val.native
		}));
		const phoneAreaList = [];
		res.result.forEach(( val ) => {
			if ( val.phone.split( ',' ).length === 1 ) {
				phoneAreaList.push({
					label: val.name === val.native ? `+${val.phone} ${val.name}` : `+${val.phone} ${val.name} ${val.native}`,
					value: `${val.phone}-${val.id}`
				});
			} else {
				val.phone.split( ',' ).map(( item ) => {
					phoneAreaList.push({
						label: val.name === val.native ? `+${item} ${val.name}` : `+${item} ${val.name} ${val.native}`,
						value: `${item}-${val.id}`
					});
				});
			}
		});
		store.commit( 'setPhone', [...phoneAreaList] || []);
		store.commit( 'setCountry', [...countryList] || []);
		return [...res.result];
	} else {
		return [];
	}
}

// 设置当前语言
function $setLang( val ) {
	if ( VueCookieNext.getCookie( 'user_id' )){
		commonApi.settingsLang({ userId: VueCookieNext.getCookie( 'user_id' ), lang: val }).then(() => {
			VueCookieNext.setCookie( 'lang', val, { expire: '3d', path: '/' });
			window.location.reload();
		}).
			catch(() => {
				$setLang();
			});
	} else {
		VueCookieNext.setCookie( 'lang', val, { expire: '3d', path: '/' });
		window.location.reload();
	}
}

// 退出登录
function $logout( redirectTo ) {
	XlMessageBox({
		title: $t( 'logout.title' ),
		message: $t( 'logout.desc' ),
		cancelButtonText: $t( 'button.cancel' ),
		confirmButtonText: $t( 'button.confirm' )
	}).then(() => {
		$exitBySearch( redirectTo || router.resolve({ name: 'oemDomainPage', params: { oemDomain: VueCookieNext.getCookie( 'oemURL' ) || 'index' } }).href );
	}).
		catch(( e ) => {
			console.log( 'catch', e );
		});
}

const $importImg = ( imgPath ) => new URL( `../image/${imgPath}`, import.meta.url ).href;
export {
	$convertUTCDateToLocalDate,
	$getInfo,
	$getCountry,
	$getPhoneArea,
	$doLang,
	$rdoLang,
	$lang,
	$setLang,
	$formErr,
	$setLocalStorage,
	$getLocalStorage,
	$removeLocalStorage,
	$checkPerm,
	$getPermList,
	$exitBySearch,
	$getCdnUrl,
	$logout,
	$getSettingList,
	$hasOnePerm,
	$copyText,
	$downloadFile,
	$openUrl,
	$importImg,
	$downloadByAlink
};
