export default [
	{
		path: '/service',
		name: 'serviceManage',
		component: () => import( '@/views/index.vue' ),
		meta: { icon: 'xiaoxitixing_xianxing' },
		children: [
			{
				path: 'customer',
				name: 'serviceCustomer',
				showSide: true,
				meta: { permission: ['btsChangeProblem'] },
				component: () => import( './customer.vue' )
			},
			{
				path: 'feedback',
				name: 'serviceFeedback',
				showSide: true,
				meta: { permission: ['btsFeedback'] },
				component: () => import( './feedback.vue' )
			}
		]
	}
];
