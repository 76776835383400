export default [
	{
		path: '/info',
		name: 'info',
		component: () => import( './index.vue' ),
		showAside: false,
		meta: {
			sideList: [
				{ path: '/info/personalInfo', name: 'personalInformation.side.info', icon: 'icon-gerenzhongxin' },
				{ path: '/info/accountPassword', name: 'personalInformation.side.password', icon: 'icon-zhanghaomima' }
			]
		},
		children: [
			{
				path: '/info/personalInfo',
				name: 'personalInfo',
				component: () => import( '@shining3d/info-component/infoList/personal-info.vue' )
			},
			{
				path: '/info/accountPassword',
				name: 'accountPassword',
				component: () => import( '@shining3d/info-component/infoList/account-password.vue' )
			}
		],
		redirect: '/info/personalInfo'
	}];
