import { createRouter, createWebHistory } from 'vue-router';
import { VueCookieNext } from 'vue-cookie-next';
import { $exitBySearch, $getPermList, $checkPerm } from '@/assets/common/common.js';
import pub from '@/views/pub/pub.js';
import info from '@/views/info/router.js';
import system from '@/views/system/router.js';
import operation from '@/views/operation/router.js';
import space from '@/views/space/router.js';
import service from '@/views/service/router';
import pubLay from '@/views/pub/index.vue';
import _get from 'lodash/get';
import $store from '../store/index.js';

let menuList = [];
let flattMenuList = [];

// 通过递归过滤路由（存在多级路由的情况
const filterRouter = ( allRouters ) => {
	const permissionRouter = [];
	for ( const route of allRouters ) {
		const tmp = { ...route };
		if ( $checkPerm( tmp.meta?.permission, $store.state.permsList )){
			flattMenuList.push( tmp );
			if ( tmp.children ) {
				tmp.children = filterRouter( tmp.children );
			}
			if ( !tmp.children || tmp.children.length > 0 ){
				permissionRouter.push( tmp );
			}
		}
	}
	return permissionRouter;
};
const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import( '@/views/home.vue' ),
		redirect: '/system/role',
		children: [
			...system,
			...operation,
			...service,
			...space,
			...info
		]
	},
	{
		path: '/p',
		name: 'pubArea',
		component: pubLay,
		children: pub
	}
];

const router = createRouter({
	history: createWebHistory( '/' ),
	routes
});

router.beforeEach( async ( to, from, next ) => {
	// 页面标题
	const title = to.name ? _get( window, `app.i18n.@shining3d/app.title.${to.name}` ) : '';
	const oemTitle = _get( $store, 'state.sysParams.title' );
	document.title = title ? title + ( oemTitle ? '-' + oemTitle : '' ) : oemTitle;
	// 如果未匹配到路由跳转info
	console.log( to, $store.state );
	if ( to.meta?.needAuth === false ) { // 对token的值进行判断，token不存在跳转登录
		next();
	} else if ( !VueCookieNext.getCookie( 'token' )) {
		$exitBySearch();
	} else if ( !$store.state.permsList || JSON.stringify( $store.state.permsList ) === '{}' ) { // permsList初始值为{}, main中删除本地存储
		await $getPermList();
		flattMenuList = [];
		menuList = filterRouter( routes );
		$store.commit( 'setMenuList', menuList );
		console.log( flattMenuList );
		if ( flattMenuList.find(( m ) => m.name === to.name )) {
			next();
		} else if ( flattMenuList.find(( r ) => !r.children )) { // 第一个叶子路由
			next({ name: flattMenuList.find(( r ) => !r.children ).name });
		} else {
			next( '/info' );
		}
	} else if ( flattMenuList.find(( m ) => m.name === to.name )) {
		next();
	} else if ( to.matched.length === 0 ) {
		if ( flattMenuList.find(( r ) => !r.children )) {
			next({ name: flattMenuList.find(( r ) => !r.children ).name });
		} else if ( from.name ) {
			next({ name: from.name });
		}
	} else {
		next( '/info' );
	}
});

export default router;
