import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store/index.js';
import { VueCookieNext } from 'vue-cookie-next';
import './assets/font/iconfont';
import './assets/font/iconfont.css';
import '@shining3d/ui-components-theme/lib/css/elCommon.css';
import VueMatomo from '@shining3d/matomo';
import _get from 'lodash/get';
import { $removeLocalStorage } from '@/assets/common/common.js';
$removeLocalStorage( 'perms' );

const userId = VueCookieNext.getCookie( 'user_id' );
const app = createApp( App );

app.use( VueMatomo, {
	trackerUrl: `/api/matomoCollector?source=${window.app.entryApiName?.toLowerCase()}`,
	trackerScriptUrl: _get( window, 'app.global.matomo.scriptUrl' ),
	siteId: _get( window, 'app.global.matomo.idsite' ),
	projectID: _get( window, 'app.global.matomo.projectId' ),
	log: true,
	// 自动注册Vue路由
	router: router,

	// 是否开启常规页面跳转追踪
	// 默认: true
	enableLinkTracking: true,

	// 再向matomo发送追踪信息前需要同意
	// 默认: false
	requireConsent: false,

	// 是否追踪初始化页面
	// 默认: true
	trackInitialView: true,

	// 是否关闭cookies运行matomo
	// 默认: false
	disableCookies: false,

	// 创建matomo服务前是否征得用户cookie授权
	// 默认: false
	requireCookieConsent: false,

	// 是否收集debug环境下的信息
	// 默认: false
	debug: false,

	// 传递给matomo的userId (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
	// 默认: undefined
	userId: userId || ''
});

// app.mixin( commonMixin );
app.use( store );
app.use( router );
app.mount( '#app' );
