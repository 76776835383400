<template>
	<router-view />
	<xl-cookies :bgImg="bgImg" :iconImg="iconImg" @openPrivacyPolicy="openPrivacyPolicy"></xl-cookies>
	<privacy-policy :type="policyData.type" :title="policyData.title" origin="bravetroops" ref="policyRef"></privacy-policy>
</template>
<script setup>
import { XLCookies as XlCookies, lowVersionOfTheTip, loadAliyunCaptchaNew } from '@shining3d/xl-login';
import privacyPolicy from '@shining3d/info-component/infoList/privacyPolicy.vue';
import { $getCountry } from '@/assets/common/common.js';
import { useStore } from 'vuex';
import throttle from 'lodash/throttle';
import bgImg from '@/assets/image/cookies.png';
import iconImg from '@/assets/image/theTriangle.svg';
lowVersionOfTheTip();
loadAliyunCaptchaNew();
const $store = useStore();
const policyData = ref({});
const policyRef = ref( null );

const openPrivacyPolicy = () => {
	$store.commit( 'setPolicy', 'privacyPolicy' );
};
// 隐私政策
const privacyPolicySwitch = computed(() => $store.state.privacyPolicySwitch );
const termsOfUseSwitch = computed(() => $store.state.termsOfUseSwitch );

const pChange = throttle(
	( title, type ) => {
		policyData.value = { type, title };
		nextTick(() => {
			policyRef.value.getInfo();
			policyRef.value.dialogVisible = true;
		});
	},
	1000,
	{ leading: true, trailing: false }
);
// 隐私政策
watch( privacyPolicySwitch, () => {
	pChange( 'app-login.privacyPolicy', 'privacy' );
});

// 使用条款
watch( termsOfUseSwitch, () => {
	pChange( 'app-login.termsOfUse', 'termsOfUse' );
});
// 语言存储
$store.commit( 'setI18n', window.app.i18n || {});
// ETCD存储
$store.commit( 'setGlobal', window.app.global || {});

$getCountry();
</script>
<style lang="scss">
@use '@/assets/common/common.scss' as *;
#app {
	width: 100%;
	height: 100%;
}
/* 清除默认的内外边距 /
/ 内减模型:盒子的边框与边距不会影响盒子的大小 */
body,
input,
img,
div,
ul,
li,
a,
span,
i,
p,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
ins,
em,
del {
	margin: 0;
	padding: 0;
}
html,
body {
	height: 100%;
	font-family: $font-family;
	-webkit-font-smoothing: antialiased;
	color: $text-color;
}

/* 清除a标签的下划线和字体颜色 */
a {
	text-decoration: none;
}

/* 清除li标签前的小点点 */
li {
	list-style: none;
}

/* 清除input标签的边框线和外轮廓线 */
input {
	border: none;
	outline: none;
}

/* 清除img标签在地基浏览器中的自带边框 /
/ 控制文字与图片的中线对齐 */
img {
	border: none;
	vertical-align: middle;
}

/* 调整浏览器的默认字体大小为12px /
/ 设置 容器高度=字体大小 */
body {
	line-height: $line-height;
}
</style>
