import axios from 'axios';
import { XlMessage } from '@shining3d/ui-components';
import qs from 'qs';
import { VueCookieNext } from 'vue-cookie-next';
import { $exitBySearch } from '@/assets/common/common.js';
import _get from 'lodash/get';

// 错误信息处理
const errMapCommon = _get( window, 'app.i18n.@shining3d/app.apiErr.common' );
const errMap = _get( window, 'app.i18n.@shining3d/app.apiErr' );

const cookie = VueCookieNext;

let codeMsg = '';
let commonMsg = '';
const $errMsg = ( errStr, errCode ) => {
	// 关闭所有弹窗
	// ElMessage.closeAll();
	let errJson = {};
	try { 
		errJson = JSON.parse( errStr );
		Object.keys( errJson ).forEach(( erk, i ) => {
			// 获取字段的错误code是否存在
			if ( errCode.startsWith( '@shining3d/' )){
				codeMsg = _get( window.app.i18n, `${errCode}.${erk + errJson[ erk ]}` );
			} else {
				codeMsg = _get( errMap, `${errCode}.${erk + errJson[ erk ]}` );
			}
			// 字段错误不存在则获取公共错误
			!codeMsg && ( commonMsg = _get( errMapCommon, `${errJson[ erk ]}` ));
			// 赋值取字段错误/公共错误/默认错误
			errJson[ erk ] = codeMsg || commonMsg || errJson[ erk ];
			// 如果字段错误不存在，公共错误存在则直接提示公共错误，不在字段中进行展示
			if ( !codeMsg && commonMsg ){
				XlMessage({ message: errJson[ erk ], type: 'error' });
			}
		});
	} catch ( e ) {
		// 解析失败则获取公共错误并直接展示
		errJson = _get( errMapCommon, `${errStr}`, _get( errMap, `${errCode}.${errStr}`, errStr ));
		XlMessage({ message: errJson, type: 'error' });
	}
	return errJson;
};

// 创建请求
export const service = axios.create({
	baseURL: window.app.baseURL,
	// baseURL: '',
	timeout: 1000 * 60,
	validateStatus: function ( status ) {
		return status >= 200 && status <= 400;
	}
});

// 请求拦截
service.interceptors.request.use(
	( config ) => {
		cookie.getCookie( 'token' ) && ( config.headers[ 'X-Auth-Token' ] = cookie.getCookie( 'token' ));
		if ( config.method === 'get' ) {
			config.paramsSerializer = function ( params ) {
				return qs.stringify( params, { arrayFormat: 'repeat' });
			};
		}
		return config;
	},
	( error ) => {
		console.error( error );
		return Promise.reject( error );
	}
);

// 响应拦截
service.interceptors.response.use(
	( response ) => {
		const data = response.data;
		if ( response.status === 200 ) {
			if ( data && data.status === 'fail' ) {
				if ( data.result === 'authErr' ) {
					// ElMessage.closeAll();
					XlMessage({
						message: _get( errMapCommon, 'landExpiration' ),
						type: 'error'
					});
					$exitBySearch();
				} else {
					return Promise.resolve( response );
				}
			}
			if ( data instanceof Blob ) {
				return response;
			}
			return Promise.resolve( response );
		} else if ( response.status === 400 ) {
			return Promise.resolve( response );
		} else {
			return Promise.reject( response );
		}
	},
	( error ) => {
		console.log( error );
		// ElMessage.closeAll();
		if ( error.message.includes( 'timeout' )) {
			// 判断请求异常信息中是否含有超时timeout字符串
			console.log( '错误回调', error );
			XlMessage.error( '网络超时' );
		}
		return Promise.reject( error );
	}
);

export function get( url, { params, errCode, ...arg } = { params: {}, errCode: '' }) {
	return service.get( url, { params }).then(( res ) => {
		// data存在并且请求失败，不为登录失效则处理报错信息
		if ( res.data && res.data.status === 'fail' && res.data.result !== 'authErr' ) {
			res.data.result = $errMsg( res.data.result, errCode ) || res.data.result;
		}
		return Promise.resolve( res.data );
	});
}
export function getConfig( url, config ) {
	return service.get( url, config ).then(( res ) => Promise.resolve( res.data ));
}
export function post( url, { errCode, data, ...arg }, config = {}) {
	return service.post( url, data, config ).then(( res ) => {
		// data存在并且请求失败，不为登录失效则处理报错信息
		if ( res.data && res.data.status === 'fail' && res.data.result !== 'authErr' ) {
			res.data.result = $errMsg( res.data.result, errCode ) || res.data.result;
		}
		return Promise.resolve( res.data );
	});
}

const http = {
	get,
	post,
	getConfig
};
export default http;
