import http from '@/utils/http';
const newRootUrl = '/api';

const commonApi = {
	/**
	 * 获取国家码
	 * @returns
	 */
	getCountry() {
		return http.get( `${newRootUrl}/fetchCountries`, { errCode: 'commonApi.getCountry' });
	},
	/**
	 *
	 * @param {String} targetData
	 */
	getSettingList( targetData ) {
		return http.get( `${newRootUrl}/resourceListV2`, { data: { nameCode: targetData }, errCode: 'commonApi.getSettingList' });
	},
	/**
	 *
	 * @param {String} targetData
	 */
	settingsLang( params ) {
		return http.get( `${newRootUrl}/setUserLangV2?lang=${params.lang}`, { errCode: 'commonApi.settingsLang' });
	}
};
export default commonApi;
